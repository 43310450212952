import React, { useEffect, useState, useRef } from 'react'
import { graphql } from 'gatsby'
import styled, { keyframes } from 'styled-components'
import colors from 'src/assets/styles/colors'
import distances from 'src/assets/styles/distances.js'
import mediaQuery from 'src/assets/styles/mediaQuery'

import typographySizes from 'src/assets/styles/typographySizes.js'
import MainWrapper from 'src/components/global/MainWrapper.js'
import Lines from 'src/components/global/lines/Lines'
import SmallTitle from 'src/components/global/texts/SmallTitle.js'
import Artist from 'src/components/Artists/Artist.js'
import arrow from 'src/assets/svg/arrow-dow-artist.svg'
import Seo from 'src/components/global/Seo.js'

const StyledMain = styled.main`
  background-color: ${colors.black};
  width: 100vw;

  ${MainWrapper} {
    height: auto;
    display: flex;
    justify-content: space-between;
  }
`

const SideCol = styled.div`
  width: 16.66%;

  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  align-self: flex-start; /* <-- this is the fix */
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
`
const showSideCol = keyframes`
  from {

    opacity: 0
  }

  to {

    opacity: 1;

  }
  `

const showSideText = keyframes`
from {
  transform: translateY(-20rem);

  opacity: 0
}

to {
  transform: translateY(0rem);

  opacity: 1;

}
`

const SideColWrap = styled.div`
  opacity: 0;
  animation: ${showSideCol} 0.5s 0.5s cubic-bezier(0.65, 0, 0.35, 1) forwards;
`

const arrowBounce = keyframes`
  from {

    transform: translateY(-10rem);
  }

  to {
    transform: translateY(10rem);

  }
`

const ArrowWrapper = styled.div`
  transform: translateY(-10rem);

  animation: ${arrowBounce} 1s cubic-bezier(0.65, 0, 0.35, 1) infinite alternate;
`

const Arrow = styled.img`
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  position: relative;

  &.bottom {
    transform: rotate(-180deg);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
`

const SideColTextWrapp = styled.div`
  overflow: hidden;
  max-width: 150rem;
  margin-top: 20rem;
  height: 28rem;
  opacity: 0;
  animation: ${showSideText} 0.5s 1s cubic-bezier(0.65, 0, 0.35, 1) forwards;

  p {
    font-size: ${typographySizes.s}rem;
    color: ${colors.white};
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1.1;
    margin-top: 2rem;
  }
  p.bottom {
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    transform: translateY(calc(-100% - 2rem));
  }
`

const MainCol = styled.div`
  width: 83.33%;
  padding-top: ${distances.mFromTop}rem;
  padding-bottom: ${distances.mBottomPage}rem;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    padding-top: ${distances.mFromTop + 50}rem;
    padding-bottom: ${distances.mBottomPage / 2}rem;
  }
`

const ArtistsWrapper = styled.div`
  margin-top: 50rem;
  margin-bottom: 50rem;

  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 25rem;
  }
`
const SmallerArtistsWrapper = styled.div`
  margin-top: 50rem;
  margin-left: 20rem;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 25rem;
  }
`

const SmallArtist = styled.span`
  color: ${colors.white};
  font-size: ${typographySizes.s}rem;
  text-transform: uppercase;
  font-weight: 400;
  white-space: nowrap;
`

const SmallArtistSeparator = styled.span`
  color: ${colors.mainColor};
  font-size: ${typographySizes.s}rem;
  text-transform: uppercase;
  font-weight: 400;
  display: inline-block;
  margin: 0 10rem;
`

const Artists = ({ data }) => {
  const [onBottom, setOnBottom] = useState(false)

  const isBrowser = typeof window !== 'undefined'
  let sideCol = useRef(null)

  useEffect(() => {
    if (isBrowser) {
      if (window.innerWidth > 900) {
        window.onscroll = function () {
          const bounds = sideCol.current.getBoundingClientRect()

          bounds.top < 0 ? setOnBottom(true) : setOnBottom(false)
        }
      }
    }
  }, [])

  return (
    <>
      <Seo
        title={
          data.strapiPageArtists.SEO ? data.strapiPageArtists.SEO.Title : null
        }
        description={
          data.strapiPageArtists.SEO
            ? data.strapiPageArtists.SEO.Description
            : null
        }
        image={
          data.strapiPageArtists.SEO
            ? data.strapiPageArtists.SEO.Img.localFile.publicURL
            : null
        }
      />
      <StyledMain>
        <MainWrapper>
          <Lines leftMargin left />
          <SideCol ref={sideCol}>
            <SideColWrap>
              <ArrowWrapper>
                <Arrow src={arrow} className={onBottom && 'bottom'} />
              </ArrowWrapper>
              <SideColTextWrapp>
                <p className={onBottom && 'bottom'}>
                  scrolluj dalej jest tego więcej
                </p>
                <p className={onBottom && 'bottom'}>
                  ty też możesz <br /> tu być
                </p>
              </SideColTextWrapp>
            </SideColWrap>
          </SideCol>
          <MainCol>
            <SmallTitle>{data.strapiPageArtists.Artists_Title}</SmallTitle>
            <ArtistsWrapper>
              {data.allStrapiArtists.edges.map(artist => (
                <>
                  <Artist info={artist.node.albums}>{artist.node.Name}</Artist>
                </>
              ))}
            </ArtistsWrapper>
            <SmallTitle>
              {data.strapiPageArtists.Small_Artists_Title}
            </SmallTitle>

            <SmallerArtistsWrapper>
              {data.strapiPageArtists.Small_Artists.map(artist => (
                <SmallArtist>
                  {artist.Name} <SmallArtistSeparator> / </SmallArtistSeparator>
                </SmallArtist>
              ))}
            </SmallerArtistsWrapper>
          </MainCol>
        </MainWrapper>
      </StyledMain>
    </>
  )
}

export const ArtistsQuery = graphql`
  query ArtistsQuery {
    allStrapiArtists(sort: { fields: order, order: ASC }) {
      edges {
        node {
          Name
          albums {
            created_at
            Cover {
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 200
                    quality: 100
                    traceSVG: { background: "#000000", color: "#FF3D00" }
                  ) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }
    strapiPageArtists {
      SEO {
        Description
        Img {
          localFile {
            publicURL
          }
        }
        Title
      }
      Artists_Title
      Small_Artists_Title
      Small_Artists {
        Name
      }
    }
  }
`

export default Artists
