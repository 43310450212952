import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import ContextConsumer from 'src/layouts/Context.js'
import styled, { keyframes } from 'styled-components'
import gsap from 'gsap'
import slugify from 'slugify'
import distances from 'src/assets/styles/distances.js'
import colors from 'src/assets/styles/colors.js'
import typographySizes from 'src/assets/styles/typographySizes.js'
import mediaQuery from 'src/assets/styles/mediaQuery'

const ArtistWrapper = styled(Link)`
  color: ${colors.mainColor};

  background-color: ${colors.black};
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  display: block;
  position: relative;
  width: calc(100%);
  position: relative;
  z-index: 0;
  left: 2px;
  line-height: 1;
`

const moveFromTop = keyframes`
  from {

    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }

  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
`

const moveToTop = keyframes`
  from {

    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }

  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
`

const moveFromBottom = keyframes`
  from {
    clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
  }

  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
`

const moveToBottom = keyframes`
  from {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }

  to {
    clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
  }
`

const Mask = styled.div`
  clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: ${colors.secColor};
  padding: 0 ${distances.s - 2}rem 0 0;

  display: flex;
  align-items: center;
  z-index: 2;
  overflow: hidden;
  @media (max-width: ${mediaQuery.tablet}) {
    padding: 0;
  }
  @media (max-width: ${mediaQuery.mobile}) {
    padding: 0;
  }
  @media (min-width: ${mediaQuery.tablet}) {
    &.top {
      animation: ${moveFromTop} 0.3s cubic-bezier(0.65, 0, 0.35, 1) forwards;
    }
    &.bottom {
      animation: ${moveFromBottom} 0.3s cubic-bezier(0.65, 0, 0.35, 1) forwards;
    }
    &.toBottom {
      animation: ${moveToBottom} 0.3s cubic-bezier(0.65, 0, 0.35, 1) forwards;
    }
    &.toTop {
      animation: ${moveToTop} 0.3s cubic-bezier(0.65, 0, 0.35, 1) forwards;
    }
  }
`

const ElText = styled.span`
  color: ${colors.mainColor};
  font-size: ${typographySizes.xl}rem;
  display: inline-block;
  background-color: '#ffffff';
  font-weight: 700;
  margin-left: 20rem;
  transition: all 0.5s 0.4s cubic-bezier(0.65, 0, 0.35, 1);
  position: relative;
  top: 5rem;
  word-wrap: break-word;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.l}rem;
    padding: 10rem 0;
  }
  @media (max-width: ${mediaQuery.mobile}) {
    font-size: ${typographySizes.m}rem;
    padding: 10rem 0;
    margin-right: 10rem;
  }
`

const ModuleWrapper = styled.span`
  display: flex;
  align-items: center;
`

const Module = styled.span`
  display: flex;
  align-items: center;
  span {
    color: ${colors.black};
    font-size: ${typographySizes.xl}rem;
    background-color: '#ffffff';
    font-weight: 700;
    position: relative;
    top: 5rem;
    white-space: nowrap;
  }
`
const StyledImg = styled(Img)`
  height: 90rem;
  width: 90rem;
  margin: 0 30rem;
  border-radius: 100%;
  background-color: black;
`

const Artist = ({ children, to, info }) => {
  const [direction, setDirection] = useState('')
  const [animationEnded, setAnimationEnded] = useState(false)
  const wrapper = useRef(null)

  const relativeCoords = e => {
    const bounds = e.target.getBoundingClientRect()
    const h = bounds.height
    const y = e.clientY - bounds.top

    if (y > h / 2) {
      setDirection('bottom')
    } else {
      setDirection('top')
    }

    setAnimationEnded(
      setTimeout(() => {
        return true
      }, 310),
    )
  }

  const relativeCoordsLeave = e => {
    const bounds = e.target.getBoundingClientRect()
    const h = bounds.height
    const y = e.clientY - bounds.top

    const intervalId = setInterval(() => {
      if (animationEnded) {
        if (y > h / 2) {
          setDirection('toBottom')
        } else {
          setDirection('toTop')
        }
        clearInterval(intervalId)
      }
    }, 10)
  }

  useEffect(() => {
    const movmentTimeline = gsap.timeline({
      repeat: -1,
    })
    const element = wrapper.current

    const bounds = element.children[0].getBoundingClientRect()
    const w = bounds.width

    movmentTimeline
      .set(element, { x: 0 })
      .to(element, { x: -w, duration: w * 0.005, ease: 'linear' })
  })

  const albumsSorted = [...info].sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at),
  )

  return (
    <ContextConsumer>
      {({ data, set }) => (
        <ArtistWrapper
          onMouseEnter={relativeCoords}
          onMouseLeave={relativeCoordsLeave}
          to={`/artysci/${slugify(children, {
            lower: true,
            strict: true,
          })}`}
        >
          <Mask className={direction}>
            <ModuleWrapper ref={wrapper}>
              {[...Array(10)].map((x, i) => (
                <Module key={i}>
                  <span>{children}</span>{' '}
                  <StyledImg
                    fluid={
                      albumsSorted[0].Cover.localFile.childImageSharp.fluid
                    }
                  />{' '}
                </Module>
              ))}
            </ModuleWrapper>
          </Mask>

          <ElText>{children}</ElText>
        </ArtistWrapper>
      )}
    </ContextConsumer>
  )
}

export default Artist
